import React from "react";
import { CheckIcon } from "@heroicons/react/solid";
import classNames from "classnames";

const StepNavigation = ({ steps }) => {
  return (
    <nav className={"tailwind mt-5 mb-10"} aria-label="Progress">
      <ol role="list" className="flex items-center">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? "pr-20" : "", "relative")}>
            {step.status === "complete" ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-1 w-full bg-resource-green-400" />
                </div>
                <span className="relative w-10 h-10 flex items-center justify-center bg-resource-green-400 rounded-full border-2 border-solid border-grey">
                  <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                  <span className={"absolute top-8 left-1/2 -translate-x-1/2 pt-4 text-base whitespace-nowrap"}>{step.name}</span>
                </span>
              </>
            ) : step.status === "current" ? (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-1 w-full bg-professional-gray" />
                </div>
                <span
                  className="relative w-10 h-10 flex items-center justify-center bg-exploratory-green-400 rounded-full border-grey border-solid "
                  aria-current="step"
                >
                  <span className="w-10 h-10 flex items-center justify-center border-4 rounded-full">
                    <span className="text-white">{step.label}</span>
                    <span
                      className={"absolute top-8 left-1/2 -translate-x-1/2 pt-4 text-base font-semibold text- text-exploratory-green-400 whitespace-nowrap"}
                    >
                      {step.name}
                    </span>
                  </span>
                </span>
              </>
            ) : (
              <>
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="h-1 w-full bg-professional-gray" />
                </div>
                <span className="group relative w-10 h-10 flex items-center justify-center bg-professional-gray border-2 border-solid border-grey rounded-full">
                  <span className="relative w-10 h-10 flex items-center justify-center rounded-full">
                    <span className="text-white">{step.label}</span>
                    <span className={"absolute top-8 left-1/2 -translate-x-1/2 pt-4 text-base"}>{step.name}</span>
                  </span>
                </span>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default StepNavigation;
