import React from "react";
import { Box, Container, Wrapper } from "../style/Grid";
import { Helmet } from "react-helmet";
import { HOTLINE } from "../components/header";
import LayoutMinimum from "../components/layoutMinimum";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import NewsListContainer from "../components/News/NewsListContainer";
import { graphql } from "gatsby";
import StepNavigation from "../components/StepNavigation";

const navigationSteps = [
  { name: "Váš telefon", label: "1", href: "#", status: "complete" },
  { name: "Děkujeme", label: "2", href: "#", status: "current" },
];

const ThankYou = ({ data }) => {
  const {
    allNews: { edges: newsNodes },
  } = data;

  return (
    <LayoutMinimum type={HOTLINE} text={"Zájem o nabídku energií od MND"}>
      <Helmet>
        <title>Zájem o nabídku energií od MND</title>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href="https://www.mnd.cz/zajem-o-nabidku-2"></link>
      </Helmet>

      <Wrapper background={["#D6D6D6"]}>
        <StepNavigation steps={navigationSteps} />
      </Wrapper>

      <Wrapper background={["white.100"]} p={["30px 20px 100px", "60px 10px 40px"]}>
        <div>
          <>
            <Box display={["block"]}>
              <Heading
                as="h2"
                fontSize={"26px !important"}
                lineHeight="28px !important"
                fontWeight="700"
                color="exploratoryGreen"
                mt={"0 !important"}
                mb={"12px"}
                textAlign={"center"}
                display={"flex"}
                justifyContent={"center"}
              >
                <Text ml={"12px !important"} mb={"0 !important"}>
                  Děkujeme za váš zájem!
                </Text>
              </Heading>
              <Heading
                as="h3"
                fontSize={"15px !important"}
                lineHeight={"24px"}
                fontWeight="500"
                color="exploratoryGreen"
                mt={"0 !important"}
                mb="16px"
                textAlign={"center"}
                display={"flex"}
                justifyContent={"center"}
              >
                Po aktualizaci naší nabídky se vám ozveme.
                <br />
                Vaše MND
              </Heading>
            </Box>
          </>
        </div>
      </Wrapper>
      <Wrapper background={["white.100"]} p={["30px 20px", "60px 10px 40px"]}>
        <Container>
          <Heading
            as="h2"
            width={"100%"}
            fontSize={"26px !important"}
            lineHeight="28px !important"
            fontWeight="700"
            color="exploratoryGreen"
            mt={"0 !important"}
            mb={"12px"}
            textAlign={"left"}
            display={"flex"}
            justifyContent={"left"}
          >
            Novinky nejen z MND
          </Heading>
          <NewsListContainer
            currentPage={0}
            news={newsNodes}
            nextPages={[null]}
            totalNews={3}
            newsPageCount={0}
            pages={[0]}
            hidePaginator={true}
            showDescription={false}
          />
        </Container>
      </Wrapper>
    </LayoutMinimum>
  );
};

export const query = graphql`
  query OfferThanksQuery {
    allNews(sort: { fields: releaseDate___date, order: DESC }, limit: 3) {
      edges {
        node {
          releaseDate {
            date
          }
          id
          name
          slug
          perex
          promolink
          calculatorType
          sidebarCalculatorType
          content
          type
          descriptionImage
        }
      }
    }
  }
`;

export default ThankYou;
